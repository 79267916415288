import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const FaCaiShen = () => (
  <StaticQuery
    query=
      {graphql`
        query {
          bg: file(relativePath: { eq: "games/fa-cai-shen.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
    render={data => (
      <div className="game-banner">
        {data.bg.childImageSharp.fluid &&
          <Img alt="Fa Cai Shen" fluid={data.bg.childImageSharp.fluid}/>
        }
      </div>
    )}
  />
);

export default FaCaiShen;